.single{
    width: 740px;
    margin: 0 auto;
    padding: 30px 0 50px;
    &__wrapper{
        width: 100%;
        & .-compact650 {
            margin: 0 auto;
            width: 650px;
        }
        & .-compact460 {
            margin: 0 auto;
            width: 460px;
        }
    }
    & .heading {
        &__tit{
            &.-center {
                margin-bottom: 20px;
                text-align: center;
                font-size: 32px;
            }
        }
    }
}

.form{
    &__heading{
        font-size: 20px;
        font-weight: bold;
        color: #3157BC;
        margin: 0 0 20px;
    }
    &__wrapper{
        margin: 20px 0 0;
        &.-first{
            margin: 20px 0 50px;
        }
        &.-goiken{
            margin: 100px 30px ;
        }
        
    }
    
    &__inner{
        background-color: #F4F9FC;
        padding:  30px;
        margin-top:20px;
        &.-captcha{
            background-color: #fff;
            padding: 0;
            border: 1px #CCDADB solid;
            border-radius: 4px;
            margin: 50px auto 0;
        }
    }
    &__clone-target{
        display: none;
    }
    &__td-flex{
        display: flex;
        &.-second-row{
            margin: 20px 0 0;
        }
    }

    &__delete-btn{
        display: flex;
        justify-content: center;
        margin: 30px 0 20px;
        input{
            &.-delete{
                font-weight: normal;
                font-size: 16px;
                background-color: transparent;
                border: 1px solid #3157bc;
                border-radius: 50px;
                text-decoration: none;
                position: relative;
                text-align: center;
                width: 314px;
                padding: 0;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #3157bc;
                flex-wrap: wrap;
                box-shadow: none;
            }
        }
    }
    &__plus-btn{
        display: flex;
        justify-content: right;
        margin: 30px 0 50px;
        input{
            &.-plus{
                margin: 0 0 0 auto;
                font-weight: normal;
                font-size: 16px;
                background-color: transparent;
                border: 1px solid #3157bc;
                border-radius: 50px;
                text-decoration: none;
                position: relative;
                text-align: center;
                height: 40px;
                width: 314px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #3157bc;
                flex-wrap: wrap;
                box-shadow: none;
            }
        }   
    }
    &__cofirm-btn{
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
        &__inner{
            text-align: center;
            position: relative;
            display: block;
            justify-content: center;
            width: 360px;
            margin: 0 auto;
            &::after{
                content: '';
                width: 8px;
                height: 8px;
                border-top: 2px solid #fff;
                border-right: 2px solid #fff;
                display: inline-block;
                position: absolute;
                top: calc(50% - 6px);
                right: 12px;
                transform: rotate(45deg);
            }
        }
        &.mt50{
            margin-top: 50px;
        }
        &.-double{
            justify-content: space-between;
            flex-direction: unset;
        }
        &.-goiken{
            justify-content: space-between;
            flex-direction: unset;
            margin: 0 30px;
        }
        &.-guideline{
            margin: 50px auto 100px;
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__cofirm-inner{
        text-align: center;
        position: relative;
        display: block;
        justify-content: center;
        width: 360px;
        margin: 0 auto;
        &::after{
            content: '';
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            display: inline-block;
            position: absolute;
            top: calc(50% - 6px);
            right: 12px;
            transform: rotate(45deg);
        }
    }
    &__submit-btn{
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    &__submit-inner{
        text-align: center;
        position: relative;
        display: block;
        justify-content: center;
        width: 360px;
        &::after{
            content: '';
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            display: inline-block;
            position: absolute;
            top: calc(50% - 6px);
            right: 12px;
            transform: rotate(45deg);
        }
        &.-right{
            &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-top: 2px solid #444;
            border-right: 2px solid #444;
            display: inline-block;
            position: absolute;
            top: calc(50% - 6px);
            right: 12px;
            transform: rotate(45deg);
            }
        }
        &.-left{
            &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-top: 2px solid #444;
            border-right: 2px solid #444;
            display: inline-block;
            position: absolute;
            top: calc(50% - 6px);
            left: 12px;
            transform: rotate(-135deg);
            }
        }
        &.-white{
            &:after{
                border-color: #fff;
            }
        }
    }
    &__captcha-upper{
        padding: 30px;
        h3{
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 10px;
        }
        img{
            width: 310px;
            margin: 20px auto 0px;
            display: block;
        }
    }
    &__captcha-lower{
        padding: 30px;
        background-color: #F4F9FC;
        th{
            padding: 20px 0 20px 0px;
        }
        tr{
            padding: 30px 0;
        }
    }
    &__center{
        line-height: 40px;
    }
    &__txt-box{
        width: 486px;
    }
}

.form__icon-plus{
    &::before{
        content: url(/assets/img/cmn_icon_plus-left.svg);
        width: 28px;
        height: 28px;
        position: absolute;
        transform: translate(8px, 6px);
    }
}  

.form__icon-delete{
    &::before{
        content: url(/assets/img/cmn_icon_delete-left.svg);
        width: 28px;
        height: 28px;
        position: absolute;
        transform: translate(8px, 6px);
    }
}

form{
    table{
        width: 100%;
        table-layout: fixed;
        tbody{
            tr{
                padding-top: 20px;
                + tr{
                    border-top: 1px solid #DAE3EC;
                }
                th{
                    padding: 20px 0 20px 10px;
                    text-align: left;
                    vertical-align: top;
                    width: 180px;
                    b {
                        font-weight: bold;
                    }
                    label {
                        font-weight: bold;
                    }
                    .required{
                        font-size: 12px;
                        display: flex;
                        padding: 2px 8px 0;
                        background-color: #c94701;
                        color: #fff;
                        text-align: left;
                        justify-content: flex-start;
                        width: fit-content;
                        border-radius: 2px;
                        margin: 5px 0 0;
                    }
                }
                td{
                    margin: 20px 0;
                    padding: 20px 0;
                    .input-wrap{
                        display: flex;
                        flex-direction: column;
                        margin: 0px 20px 0px 0;
                        position: relative;
                        &.-second{
                            margin: 20px 0px 0px 0;
                        }
                        &.-row{
                            flex-direction: row;
                            &.-valign{
                                align-items: center;
                            }
                        }
                        
                        &.-error{
                            input{
                                outline:#C94701;
                                border: 1px solid #C94701;
                            }
                            textarea{
                                outline:#C94701;
                                border: 1px solid #C94701;
                            }
                            &:after{
                                content: url(/assets/img/cmn_icon_validation.svg);
                                width: 16px;
                                height: 16px;
                                position: absolute;
                                right: 10px;
                                bottom: 13px;
                            }
                        }
                        span{
                            display: inline-flex;
                            align-items: flex-end;
                        }
                    }
                }
            }
        }
    }
}

.login{
    &__description {
        ul {
            li {
                padding-left: 1em;
                text-indent: -1em;
            }
        }
    }
    &__inner{
        margin: 30px 0 0 0;
        padding: 10px 30px;
        background-color: #f4f9fc;
    }
    &__block{
        padding: 20px 0;
        border-bottom: 1px solid #dae3ec;
        &:last-child {
            border-bottom: 0;
        }
    }
    &__caption{
        margin: 20px 0 10px 0;
        font-weight: bold;
        &:first-child {
            margin-top: 0;
        }
    }
    &__input{
        & input{
            display: block;
            width: 100%;
        }
        & select{
            display: block;
            width: 100%;
        }
        &.-error{
            position: relative;
            input{
                outline:#C94701;
                border: 1px solid #C94701;
            }
            &:after{
                content: url(/assets/img/cmn_icon_validation.svg);
                width: 16px;
                height: 16px;
                position: absolute;
                right: 10px;
                bottom: 13px;
            }
        }
    }
    &__submit{
        margin: 30px -10px;
        display: flex;
        justify-content: space-between;
        &-inner{
            padding: 0 10px;
            width: 100%;
        }
    }
    &__button{
        position: relative;
        width: 100%;
        & .button{
            width: 100%;
        }
        &::after{
            content: '';
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            display: inline-block;
            position: absolute;
            top: calc(50% - 6px);
            right: 12px;
            transform: rotate(45deg);
            pointer-events: none;
        }
        &.-back{
            &::after{
                border-color: #444;
                right: auto;
                left: 12px;
                transform: rotate(-135deg);
            }
        }
    }
    &__menu{
        & li{
            margin: 5px 0;
        }
    }
    &__note {
		margin: 10px 0 0 0;
        font-size: 12px;
    }
    &__color-red {
        color: #c94701;
    }
}

.complete{
    &__icon{
        margin: 30px auto 50px auto;
        width: 140px;
        height: 140px;
        background-image: url('/assets/img/cmn_icon_complete.svg');
        background-size: contain;
    }
}

.certificate{
    &__table{
        margin: 60px 0 0 0;
        table{
            width: 100%;
        }
        th{
            padding: 5px 10px;
            border: 1px solid #fff;
            background-color: #3157bc;
            text-align: center;
            color: #fff;
        }
        td{
            padding: 15px 10px;
            border: 1px solid #fff;
            background-color: #f4f9fc;
            text-align: center;
        }
    }
    &__wrapper{
        margin: 30px 0 0 0;
        padding: 20px 0 0 0;
    }
    &__heading{
        margin: 0 0 20px 0;
        font-weight: bold;
        font-size: 20px;
        color: #3157bc;
    }
    &__inner{
        padding: 30px;
        background-color: #f4f9fc;
        table{
            width: 100%;
        }
        th{
            padding: 20px 10px;
            width: 180px;
            border-bottom: 1px solid #dae3ec;
            text-align: left;
            font-weight: bold;
        }
        td{
            padding: 20px 0;
            border-bottom: 1px solid #dae3ec;
            word-break: break-all;
            text-align: left;
        }
        tr:last-child{
            th,td{
                border-bottom: 0;
            }
        }
    }
    &__close{
        display: flex;
        justify-content: center;
    }
}

.label{
    &-cofirm{
        display: inline-flex;
        align-items: center;
        margin: 0px 0 30px;
        span{
            display: inline-flex;
    align-items: flex-end;
        }
    }
    &.-goiken-th{
        margin: 10px 0 0 ;
        display: block;
    }
}
input{
    font-size: 16px;
    padding: 9px 10px 7px;
    border: 1px solid #CCDADB;
    border-radius: 0px;
    caret-color: #3157BC;
    height: 40px;
    &:hover{
        cursor: pointer;
        border: 1px solid #3157BC;
        outline: none;
    }
    &:focus{
        border: 1px solid #3157BC;
        outline: none;
    }
    &.-short{
        width: 160px;
    }
    &.-long{
        width: 340px;
    }
    &.-add-text{
        margin: 13px 0 0;
    }
}
input[type="text"]{
    &:hover{
        cursor:auto;
    }
    &.-post{
        width: 120px;
    }
    &.-date{
        width: 45px;
        margin: 0 10px 0 0;
    }
    &.-large{
        width: 285px;
    }
}
input[type="button"]{
    
    box-shadow: 0 2px 0 #000e5c;
    padding: 22px 0;
    width: 360px;
    height: 60px;
    border: transparent;
    color: #444444;
    &.-second{
        background-color: #f5e06b;
    }
}
/* Hide Default Checkbox */
input[type="checkbox"] {
    height: 22px;
    opacity:0;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    }

  /* Create a Box */
input[type="checkbox"] + span::before {
    display: inline-block;
    content: '';
    background-image: url("/assets/img/cmn_icon_checkbox-unchecked.svg");
    width: 30px;
    height: 30px;
    border: none;
    margin-right: 10px;
}

  /* Checked Style */
input[type="checkbox"]:checked + span::before {
    background-image: url("/assets/img/cmn_icon_checkbox-checked.svg");
    background-repeat: no-repeat;
}

  /* Focused Style */
input[type="checkbox"]:focus + span::before {
    border-color: #374BAE;
}


/* Hide Default Checkbox */
input[type="radio"] {
    opacity:0;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
}

  /* Create a Box */
input[type="radio"] + span::before {
    display: inline-block;
    content: '';
    background-image: url("/assets/img/cmn_icon_radio-unchecked.svg");
    width: 30px;
    height: 30px;
    border: none;
    margin-right: 10px;
}

  /* Checked Style */
input[type="radio"]:checked + span::before {
    background-image: url("/assets/img/cmn_icon_radio-checked.svg");
    background-repeat: no-repeat;
}

  /* Focused Style */
input[type="radio"]:focus + span::before {
    border-color: #374BAE;
}

select{
    background-image: url("/assets/img/cmn_icon_select-right.svg");
    padding: 9px 10px 7px;
    background-position: right 0px center;
    background-repeat: no-repeat;
    background-size: 40px 41px;
    border: 1px solid #CCDADB;
    box-shadow: #ccdadb 0px 2px;
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0px;
    height: 40px;
    cursor: pointer;
    &:hover {
        cursor: pointer;
    }
    &.-short{
        width: 160px;
    }
    &.-long{
        width: 340px;
    }
    &.-year{
        width: 100px;
    }
    &.-committee {
        width: 184px;
        margin: 0 10px 0 0;
    }
    &.-state{
        width: 200px;
    }
}
select:invalid {
    color: #747474;
    cursor: pointer;
    &:hover {
        cursor: pointer;
    }
}
select:focus {
    outline: 1px solid#374BAE;
    cursor: pointer;
}
textarea{
    font-size: 16px;
    padding: 9px 10px 7px;
    border: 1px solid #CCDADB;
    border-radius: 0px;
    caret-color: #3157BC;
    resize: none;
    &:hover{
        cursor:auto;
    }
}
textarea:focus{
    border: 1px solid #3157BC;
    outline: none;
}
textarea:hover {
    border: 1px solid #3157BC;
    outline: none;
}

