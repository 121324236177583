.single {
  width: 740px;
  margin: 0 auto;
  padding: 30px 0 50px;
}

.single__wrapper {
  width: 100%;
}

.single__wrapper .-compact650 {
  margin: 0 auto;
  width: 650px;
}

.single__wrapper .-compact460 {
  margin: 0 auto;
  width: 460px;
}

.single .heading__tit.-center {
  margin-bottom: 20px;
  text-align: center;
  font-size: 32px;
}

.form__heading {
  font-size: 20px;
  font-weight: bold;
  color: #3157BC;
  margin: 0 0 20px;
}

.form__wrapper {
  margin: 20px 0 0;
}

.form__wrapper.-first {
  margin: 20px 0 50px;
}

.form__wrapper.-goiken {
  margin: 100px 30px;
}

.form__inner {
  background-color: #F4F9FC;
  padding: 30px;
  margin-top: 20px;
}

.form__inner.-captcha {
  background-color: #fff;
  padding: 0;
  border: 1px #CCDADB solid;
  border-radius: 4px;
  margin: 50px auto 0;
}

.form__clone-target {
  display: none;
}

.form__td-flex {
  display: flex;
}

.form__td-flex.-second-row {
  margin: 20px 0 0;
}

.form__delete-btn {
  display: flex;
  justify-content: center;
  margin: 30px 0 20px;
}

.form__delete-btn input.-delete {
  font-weight: normal;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #3157bc;
  border-radius: 50px;
  text-decoration: none;
  position: relative;
  text-align: center;
  width: 314px;
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3157bc;
  flex-wrap: wrap;
  box-shadow: none;
}

.form__plus-btn {
  display: flex;
  justify-content: right;
  margin: 30px 0 50px;
}

.form__plus-btn input.-plus {
  margin: 0 0 0 auto;
  font-weight: normal;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #3157bc;
  border-radius: 50px;
  text-decoration: none;
  position: relative;
  text-align: center;
  height: 40px;
  width: 314px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3157bc;
  flex-wrap: wrap;
  box-shadow: none;
}

.form__cofirm-btn {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.form__cofirm-btn__inner {
  text-align: center;
  position: relative;
  display: block;
  justify-content: center;
  width: 360px;
  margin: 0 auto;
}

.form__cofirm-btn__inner::after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
}

.form__cofirm-btn.mt50 {
  margin-top: 50px;
}

.form__cofirm-btn.-double {
  justify-content: space-between;
  flex-direction: unset;
}

.form__cofirm-btn.-goiken {
  justify-content: space-between;
  flex-direction: unset;
  margin: 0 30px;
}

.form__cofirm-btn.-guideline {
  margin: 50px auto 100px;
  flex-direction: row;
  justify-content: space-between;
}

.form__cofirm-inner {
  text-align: center;
  position: relative;
  display: block;
  justify-content: center;
  width: 360px;
  margin: 0 auto;
}

.form__cofirm-inner::after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
}

.form__submit-btn {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.form__submit-inner {
  text-align: center;
  position: relative;
  display: block;
  justify-content: center;
  width: 360px;
}

.form__submit-inner::after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
}

.form__submit-inner.-right:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #444;
  border-right: 2px solid #444;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
}

.form__submit-inner.-left:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #444;
  border-right: 2px solid #444;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  left: 12px;
  transform: rotate(-135deg);
}

.form__submit-inner.-white:after {
  border-color: #fff;
}

.form__captcha-upper {
  padding: 30px;
}

.form__captcha-upper h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px;
}

.form__captcha-upper img {
  width: 310px;
  margin: 20px auto 0px;
  display: block;
}

.form__captcha-lower {
  padding: 30px;
  background-color: #F4F9FC;
}

.form__captcha-lower th {
  padding: 20px 0 20px 0px;
}

.form__captcha-lower tr {
  padding: 30px 0;
}

.form__center {
  line-height: 40px;
}

.form__txt-box {
  width: 486px;
}

.form__icon-plus::before {
  content: url(/assets/img/cmn_icon_plus-left.svg);
  width: 28px;
  height: 28px;
  position: absolute;
  transform: translate(8px, 6px);
}

.form__icon-delete::before {
  content: url(/assets/img/cmn_icon_delete-left.svg);
  width: 28px;
  height: 28px;
  position: absolute;
  transform: translate(8px, 6px);
}

form table {
  width: 100%;
  table-layout: fixed;
}

form table tbody tr {
  padding-top: 20px;
}

form table tbody tr + tr {
  border-top: 1px solid #DAE3EC;
}

form table tbody tr th {
  padding: 20px 0 20px 10px;
  text-align: left;
  vertical-align: top;
  width: 180px;
}

form table tbody tr th b {
  font-weight: bold;
}

form table tbody tr th label {
  font-weight: bold;
}

form table tbody tr th .required {
  font-size: 12px;
  display: flex;
  padding: 2px 8px 0;
  background-color: #c94701;
  color: #fff;
  text-align: left;
  justify-content: flex-start;
  width: fit-content;
  border-radius: 2px;
  margin: 5px 0 0;
}

form table tbody tr td {
  margin: 20px 0;
  padding: 20px 0;
}

form table tbody tr td .input-wrap {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 0;
  position: relative;
}

form table tbody tr td .input-wrap.-second {
  margin: 20px 0px 0px 0;
}

form table tbody tr td .input-wrap.-row {
  flex-direction: row;
}

form table tbody tr td .input-wrap.-row.-valign {
  align-items: center;
}

form table tbody tr td .input-wrap.-error input {
  outline: #C94701;
  border: 1px solid #C94701;
}

form table tbody tr td .input-wrap.-error textarea {
  outline: #C94701;
  border: 1px solid #C94701;
}

form table tbody tr td .input-wrap.-error:after {
  content: url(/assets/img/cmn_icon_validation.svg);
  width: 16px;
  height: 16px;
  position: absolute;
  right: 10px;
  bottom: 13px;
}

form table tbody tr td .input-wrap span {
  display: inline-flex;
  align-items: flex-end;
}

.login__description ul li {
  padding-left: 1em;
  text-indent: -1em;
}

.login__inner {
  margin: 30px 0 0 0;
  padding: 10px 30px;
  background-color: #f4f9fc;
}

.login__block {
  padding: 20px 0;
  border-bottom: 1px solid #dae3ec;
}

.login__block:last-child {
  border-bottom: 0;
}

.login__caption {
  margin: 20px 0 10px 0;
  font-weight: bold;
}

.login__caption:first-child {
  margin-top: 0;
}

.login__input input {
  display: block;
  width: 100%;
}

.login__input select {
  display: block;
  width: 100%;
}

.login__input.-error {
  position: relative;
}

.login__input.-error input {
  outline: #C94701;
  border: 1px solid #C94701;
}

.login__input.-error:after {
  content: url(/assets/img/cmn_icon_validation.svg);
  width: 16px;
  height: 16px;
  position: absolute;
  right: 10px;
  bottom: 13px;
}

.login__submit {
  margin: 30px -10px;
  display: flex;
  justify-content: space-between;
}

.login__submit-inner {
  padding: 0 10px;
  width: 100%;
}

.login__button {
  position: relative;
  width: 100%;
}

.login__button .button {
  width: 100%;
}

.login__button::after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
  pointer-events: none;
}

.login__button.-back::after {
  border-color: #444;
  right: auto;
  left: 12px;
  transform: rotate(-135deg);
}

.login__menu li {
  margin: 5px 0;
}

.login__note {
  margin: 10px 0 0 0;
  font-size: 12px;
}

.login__color-red {
  color: #c94701;
}

.complete__icon {
  margin: 30px auto 50px auto;
  width: 140px;
  height: 140px;
  background-image: url("/assets/img/cmn_icon_complete.svg");
  background-size: contain;
}

.certificate__table {
  margin: 60px 0 0 0;
}

.certificate__table table {
  width: 100%;
}

.certificate__table th {
  padding: 5px 10px;
  border: 1px solid #fff;
  background-color: #3157bc;
  text-align: center;
  color: #fff;
}

.certificate__table td {
  padding: 15px 10px;
  border: 1px solid #fff;
  background-color: #f4f9fc;
  text-align: center;
}

.certificate__wrapper {
  margin: 30px 0 0 0;
  padding: 20px 0 0 0;
}

.certificate__heading {
  margin: 0 0 20px 0;
  font-weight: bold;
  font-size: 20px;
  color: #3157bc;
}

.certificate__inner {
  padding: 30px;
  background-color: #f4f9fc;
}

.certificate__inner table {
  width: 100%;
}

.certificate__inner th {
  padding: 20px 10px;
  width: 180px;
  border-bottom: 1px solid #dae3ec;
  text-align: left;
  font-weight: bold;
}

.certificate__inner td {
  padding: 20px 0;
  border-bottom: 1px solid #dae3ec;
  word-break: break-all;
  text-align: left;
}

.certificate__inner tr:last-child th, .certificate__inner tr:last-child td {
  border-bottom: 0;
}

.certificate__close {
  display: flex;
  justify-content: center;
}

.label-cofirm {
  display: inline-flex;
  align-items: center;
  margin: 0px 0 30px;
}

.label-cofirm span {
  display: inline-flex;
  align-items: flex-end;
}

.label.-goiken-th {
  margin: 10px 0 0;
  display: block;
}

input {
  font-size: 16px;
  padding: 9px 10px 7px;
  border: 1px solid #CCDADB;
  border-radius: 0px;
  caret-color: #3157BC;
  height: 40px;
}

input:hover {
  cursor: pointer;
  border: 1px solid #3157BC;
  outline: none;
}

input:focus {
  border: 1px solid #3157BC;
  outline: none;
}

input.-short {
  width: 160px;
}

input.-long {
  width: 340px;
}

input.-add-text {
  margin: 13px 0 0;
}

input[type="text"]:hover {
  cursor: auto;
}

input[type="text"].-post {
  width: 120px;
}

input[type="text"].-date {
  width: 45px;
  margin: 0 10px 0 0;
}

input[type="text"].-large {
  width: 285px;
}

input[type="button"] {
  box-shadow: 0 2px 0 #000e5c;
  padding: 22px 0;
  width: 360px;
  height: 60px;
  border: transparent;
  color: #444444;
}

input[type="button"].-second {
  background-color: #f5e06b;
}

/* Hide Default Checkbox */
input[type="checkbox"] {
  height: 22px;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}

/* Create a Box */
input[type="checkbox"] + span::before {
  display: inline-block;
  content: '';
  background-image: url("/assets/img/cmn_icon_checkbox-unchecked.svg");
  width: 30px;
  height: 30px;
  border: none;
  margin-right: 10px;
}

/* Checked Style */
input[type="checkbox"]:checked + span::before {
  background-image: url("/assets/img/cmn_icon_checkbox-checked.svg");
  background-repeat: no-repeat;
}

/* Focused Style */
input[type="checkbox"]:focus + span::before {
  border-color: #374BAE;
}

/* Hide Default Checkbox */
input[type="radio"] {
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}

/* Create a Box */
input[type="radio"] + span::before {
  display: inline-block;
  content: '';
  background-image: url("/assets/img/cmn_icon_radio-unchecked.svg");
  width: 30px;
  height: 30px;
  border: none;
  margin-right: 10px;
}

/* Checked Style */
input[type="radio"]:checked + span::before {
  background-image: url("/assets/img/cmn_icon_radio-checked.svg");
  background-repeat: no-repeat;
}

/* Focused Style */
input[type="radio"]:focus + span::before {
  border-color: #374BAE;
}

select {
  background-image: url("/assets/img/cmn_icon_select-right.svg");
  padding: 9px 10px 7px;
  background-position: right 0px center;
  background-repeat: no-repeat;
  background-size: 40px 41px;
  border: 1px solid #CCDADB;
  box-shadow: #ccdadb 0px 2px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0px;
  height: 40px;
  cursor: pointer;
}

select:hover {
  cursor: pointer;
}

select.-short {
  width: 160px;
}

select.-long {
  width: 340px;
}

select.-year {
  width: 100px;
}

select.-committee {
  width: 184px;
  margin: 0 10px 0 0;
}

select.-state {
  width: 200px;
}

select:invalid {
  color: #747474;
  cursor: pointer;
}

select:invalid:hover {
  cursor: pointer;
}

select:focus {
  outline: 1px solid #374BAE;
  cursor: pointer;
}

textarea {
  font-size: 16px;
  padding: 9px 10px 7px;
  border: 1px solid #CCDADB;
  border-radius: 0px;
  caret-color: #3157BC;
  resize: none;
}

textarea:hover {
  cursor: auto;
}

textarea:focus {
  border: 1px solid #3157BC;
  outline: none;
}

textarea:hover {
  border: 1px solid #3157BC;
  outline: none;
}
